<template>
  <div style="padding: 20px;">
    <portal to="view-title">
      <i class="mdi mdi-book" />
      Documentation
    </portal>
    <b-tabs>
      <b-tab-item label="Guides d'aide">
        <div v-for="(folderPages, folderName) in pagesByFolder" :key="folderName">
          <h3>{{folderName}}</h3>
          <ul>
            <li v-for="d in folderPages" :key="d._id">
               <nuxt-link
                :to="`/documentation/${d._id}`"
                :data-panel-name="d.title"
                exact-active-class="is-active"
              >
                <i class="mdi mdi-table" />
                {{d.title}}
              </nuxt-link>
            </li>
          </ul>
        </div>
      </b-tab-item>
      <b-tab-item label="Champs de formulaire">
        <DocFieldList />
      </b-tab-item>
      <b-tab-item label="Référence">
        <div v-for="(refItems, type) in refItemsByType" :key="type">
          <h3>{{type}}</h3>
          <ul>
            <li v-for="i in refItems" :key="i._id">
              <nuxt-link
                :to="`/documentation/reference/${i._id}`"
                :data-panel-name="i.name"
                exact-active-class="is-active"
              >
                <i class="mdi mdi-table" />
                {{i.name}}
              </nuxt-link>
            </li>
          </ul>
        </div>
      </b-tab-item>
      <b-tab-item label="Tickets ouverts">
        <b-collapse v-for="i in issues" :key="i._id" class="card" :open="false">
          <template #trigger="props">
            <div class="card-content" style="padding: 10px;">
              <b-icon v-if="i.probleme" :icon="props.open ? 'menu-down' : 'menu-right'" />
              <span class="tag">{{i.referentiel}}</span>&nbsp;
              <span class="tag"> <i class="mdi mdi-date" />
                {{new Date(i._metadatas.creationDate).toLocaleDateString()}}
              </span>
              {{i.description}}
              <span v-if="i.demandeur" style="float: right; margin-top: 6px;" class="tag">
                <i class="mdi mdi-account" />
                &nbsp;
                {{i.demandeur}}
              </span>
            </div>
          </template>
          <div class="card-content" style="padding: 0px; border-top: 3px solid whitesmoke">
            <pre style="white-space: pre-wrap;">{{i.probleme}}</pre>
          </div>
        </b-collapse>
      </b-tab-item>
    </b-tabs>
    <div v-if="!loaded">
      <i class="mdi mdi-loading" /> Loading documentation, please wait...
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import DocFieldList from '@/components/DocFieldList';

export default {
  name: 'DocumentationPage',
  components: {
    DocFieldList,
  },
  async mounted() {
    this.$store.dispatch('documentation/load');
    const res = await
    this.$axios.get('https://server.gwenp.fr:3333/dashy-bugs?query={"solved": { "$ne": true }, "lowCriticity": { "$ne": true } }', {
      headers: { authorization: `apiKey ${process.env.VUE_APP_REFERENTIEL_API}` },
    });
    this.issues = res.data.documents;
  },
  data() {
    return {
      issues: [],
    };
  },
  computed: {
    ...mapState({
      pages: (state) => state.documentation.pages,
      referenceItems: (state) => state.documentation.referenceItems,
      loaded: (state) => state.documentation.loaded,
    }),
    pagesByFolder() {
      const res = {};
      this.pages.map((p) => {
        if (res[p.folder] === undefined) {
          res[p.folder] = [];
        }
        res[p.folder].push(p);
      });
      function compare(a, b) {
        let comparison = 0;
        if (a.title > b.title) {
          comparison = 1;
        } else if (a.title < b.title) {
          comparison = -1;
        }
        return comparison;
      }

      for (const f in res) {
        console.log('f', res[f].sort(compare));
        res[f] = res[f].sort(compare);
      }
      return res;
    },
    refItemsByType() {
      const res = {};
      this.referenceItems.map((p) => {
        if (res[p.type] === undefined) {
          res[p.type] = [];
        }
        res[p.type].push(p);
      });
      return res;
    },
  },
};
</script>
<style scoped>
</style>
