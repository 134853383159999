<template>
  <div>
    <h3>Imports not found</h3>
    {{importsNotFound.join(', ')}}
    <div class="field-list">
      <b-tabs vertical type="is-boxed">
        <b-tab-item :label="`Tout (${fields.length})`">
          <div v-for="f in fields" :key="f.name" class="card" @click="selectedField = f">
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                  <figure class="image is-24x24">
                    <i :class="`mdi mdi-${f.icon} mdi-24px`" />
                  </figure>
                </div>
                <div class="media-content">
                  <p class="title is-6" style="margin:0">
                    {{f.label}}
                    <span v-if="f.deprecated" class="tag is-warning">
                      Déprécié
                    </span>
                  </p>
                  <p class="">{{f.description}}</p>
                </div>
              </div>
              <!--<div class="content">

              </div>-->
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-for="(fields, category) in categories" :label="`${category} (${fields.length})`" :key="c">
          <div v-for="f in fields" :key="f.name" class="card" @click="selectedField = f">
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                  <figure class="image is-24x24">
                    <i :class="`mdi mdi-${f.icon} mdi-24px`" />
                  </figure>
                </div>
                <div class="media-content">
                  <p class="title is-6" style="margin:0">
                    {{f.label}}
                    <span v-if="f.deprecated" class="tag is-warning">
                      Déprécié
                    </span>
                  </p>
                  <p class="">{{f.description}}</p>
                </div>
              </div>
              <!--<div class="content">

              </div>-->
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>

    <Modal v-if="!!selectedField" :active="!!selectedField" :width="1400" @close="closeModal()">
      <template slot="header">
        <i :class="`mdi mdi-${selectedField.icon}`" style="margin-right: 10px"/>
        {{selectedField.label}}
      </template>
      <div class="title is-4">
        {{selectedField.description}}
      </div>
      <div>
        <div class="card">
          <div class="card-header">
            <span class="card-header-icon">
              <i class="mdi mdi-wrench" />
            </span>
            <p class="card-header-title">
              Options
            </p>
          </div>
          <div class="card-content">
            <div class="content">
              <ul>
                <li v-if="selectedField.specificOptions === undefined || selectedField.specificOptions.length === 0">
                  Pas d'options spécifiques à ce champ.
                </li>
                <li v-for="o in selectedField.specificOptions" :key="o.model">
                  <b>{{o.label}}</b>
                  <span class="tag">
                    {{o.type}}
                  </span>
                  <div>
                    {{o.hint}}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <h2 class="card-header-title">
            Exemples
          </h2>
          <b-collapse
            class="card"
            v-for="(s, index) in storiesFor(selectedField.originalLabel)"
            :key="s.id"
            :open="isOpen === s.id"
            @open="isOpen = s.id">
            <template #trigger="props">
              <div class="card-header" role="button">
                <a class="card-header-icon">
                  <i :class="props.open ? 'mdi mdi mdi-menu-down' : 'mdi mdi-menu-right'" />
                </a>
                <span class="card-header-title">
                  {{ s.story }}&nbsp;&nbsp;
                  <a :href="`https://dashy-storybook.netlify.app/?path=/story/${s.id}`" target="_blank">
                    <i class="mdi mdi-open-in-new" />
                  </a>
                </span>
              </div>
            </template>
            <div class="content" style="margin-top: -10px; position: relative; height: 690px; overflow: hidden">
              <span style="position: absolute; top: 20px; left: 20px; z-index: 1">
                Chargement...
              </span>
              <iframe
                :src="`https://dashy-storybook.netlify.app/iframe.html?path=/story/${s.id}`"
                style="width: 100%; height: 710px; position: absolute; z-index: 2; top: -10px">
              </iframe>
            </div>
          </b-collapse>
          <b-message v-if="storiesFor(selectedField.originalLabel) === undefined">
            Pas d'exemple d'utilisation pour le moment
          </b-message>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/modals/Modal';
import Api from '@/core/Api';
import deepClone from '@/core/utils/deepClone';

export default {
  components: {
    Modal,
  },
  data () {
    Api.get('/system_referentiel').then(response => {
      if (response.data.storybookInfo) {
        for (let storyName in response.data.storybookInfo.stories) {
          const s = response.data.storybookInfo.stories[storyName];
          if (this.storiesByKind[s.kind] === undefined) {
            this.storiesByKind[s.kind] = [];
          }
          this.storiesByKind[s.kind].push(s);
        }
      }
      this.storiesByKind = deepClone(this.storiesByKind);
    });
    const fields = $dashy.listFields();

    const categories = {};
    fields.map(f => {
      if (categories[f.category] === undefined) {
        categories[f.category] = [];
      }
      categories[f.category].push(f);
    });

    return {
      fields,
      categories,
      selectedField: undefined,
      storiesByKind: {},
      isOpen: undefined,
    };
  },
  computed: {
    importsNotFound () {
      return this.fields.filter(f => this.$options.components[f.name] === undefined).map(f => f.name);
    }
  },
  methods: {
    storiesFor(label) {
      console.log('storiesFor', label, this.storiesByKind);
      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      return this.storiesByKind[`Fields/${capitalizeFirstLetter(label)}`];
    },
    closeModal () {
      this.selectedField = undefined;
      this.isOpen = undefined;
    }
  },
};
</script>
<style scoped>
</style>
