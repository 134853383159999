<template>
  <div style="padding: 20px;">
    <portal v-if="loaded" to="view-title">
      <nuxt-link :to="`/documentation`">
        <i class="mdi mdi-book" />
        Documentation
      </nuxt-link>
      <i class="mdi mdi-menu-right" />{{currentPage.folder}}<i class="mdi mdi-menu-right" />{{currentPage.title}}
    </portal>
    <div v-if="!loaded">
      <i class="mdi mdi-loading" /> Loading documentation, please wait...
    </div>
    <div class="documentation-content">
      <v-runtime-template v-if="currentPage.content" :template="`<div>${currentPage.content}</div>`" />
    </div>
    <b-menu class="documentation-nav">
      <b-menu-list label="Navigation">
        <b-menu-item v-for="i in navContent" :key="i" icon="file" :label="i" />
      </b-menu-list>
    </b-menu>
    <div class="navigation-bar">
      <span v-if="nextPage" style="float:right">
        <nuxt-link :to="`/documentation/${nextPage._id}`">
          {{nextPage.title}}
          <i class="mdi mdi-arrow-right" />
        </nuxt-link>
      </span>
      <span v-if="prevPage">
        <nuxt-link :to="`/documentation/${prevPage._id}`">
          <i class="mdi mdi-arrow-left" />
          {{prevPage.title}}
        </nuxt-link>
      </span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import VRuntimeTemplate from 'v-runtime-template';
import Vue from 'vue';
import VueSilentbox from 'vue-silentbox';
import DocumentationIndexPage from './index.vue';
import DocImages from '../../components/ui/DocImages';

Vue.use(VueSilentbox);

export default {
  name: 'DocumentationPage',
  components: {
    VRuntimeTemplate,
    DocImages,
  },
  extends: DocumentationIndexPage,
  mounted() {
    this.$store.dispatch('documentation/load');
  },
  data() {
    return {
      navContent: [],
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.refreshNav();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      pages: (state) => state.documentation.pages,
      loaded: (state) => state.documentation.loaded,
    }),
    currentPage() {
      if (this.loaded) {
        return this.pages.filter((p) => p._id === this.$route.params.id)[0];
      }
      return undefined;
    },
    prevPage() {
      if (this.currentPage) {
        console.log('nextPage CP', this.pagesByFolder[this.currentPage.folder]);
        const pagesInFolder = this.pagesByFolder[this.currentPage.folder];
        for (let i = 0; i < pagesInFolder.length; i++) {
          if (pagesInFolder[i]._id === this.currentPage._id) {
            return pagesInFolder[i - 1];
          }
        }
      }
      return undefined;
    },
    nextPage() {
      if (this.currentPage) {
        console.log('nextPage CP', this.pagesByFolder[this.currentPage.folder]);
        const pagesInFolder = this.pagesByFolder[this.currentPage.folder];
        for (let i = 0; i < pagesInFolder.length; i++) {
          if (pagesInFolder[i]._id === this.currentPage._id) {
            return pagesInFolder[i + 1];
          }
        }
      }
      return undefined;
    },
  },
  methods: {
    refreshNav() {
      this.$nextTick(() => {
        if (this.$el && this.$el.querySelectorAll) {
          this.navContent = Array.from(this.$el.querySelectorAll('h3')).map(((e) => e.innerHTML));
        }
      });
    },
  },
};
</script>
<style scoped>
.documentation-content {
  padding-right: 300px;
}
.documentation-nav {
  position: fixed;
  top: 100px;
  right: 0;
  width: 300px;
  background: #efefef;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.documentation-nav >>> .menu-list {
  margin: 0;
  list-style: none;
  font-size: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
}

.navigation-bar {
  position: fixed;
  padding-top: 12px;
  padding-right: 120px;
  padding-left: 20px;
  padding-bottom: 2px;
  bottom: 0;
  right: 0;
  height: 48px;
  width: calc(100% - 300px);
  margin-right: 0;
  background: whitesmoke;
  box-shadow: 0 -2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
</style>
