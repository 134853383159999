<template>
    <silent-box :gallery="computedImages"/>
</template>
<script>
export default {
  props: {
    images: {
      type: Array,
      default: undefined,
    },
    thumbnailWidth: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    const images = this.images || [];
    return {
      computedImages: images.map((img) => ({
        ...img,
        src: process.env.VUE_APP_DOCIMAGES_BASEURL + img.src,
        thumbnailWidth: this.thumbnailWidth,
      })),
    };
  },
};
</script>
